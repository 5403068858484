import React from "react";
import { CopyrightWrapper } from "./copyright.style";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Copyright = () => {
  const navigation = [
    { name: "Footer.cookies", href: "/cookies" },
   
  ];
  const { t } = useTranslation();

  return (
    <CopyrightWrapper>
      <span>© Mínima - Médicos & Arte lda. 2025</span>
      {/* <div className="navigation">
        {navigation.map((item) => (
          <Link className="linkstyle" key={item.name} to={item.href}>
              {t(item.name)}
          </Link>
        ))}
      </div> */}
    </CopyrightWrapper>
  );
};

export default Copyright;
