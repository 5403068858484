import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CalculatorContainer, CalculatorWrapper } from "./calculator.style";
import { Button } from "_components/atoms";
import { Link } from "react-router-dom";

const Calculator = () => {
  const [postalCode, setPostalCode] = useState('');
  const navigate = useNavigate();

  const handleCalculate = () => {
    navigate('/calculator', { state: { postalCode } });
  };

  const formatPostalCode = (value) => {
    // Remove caracteres que não sejam números
    let numbers = value.replace(/\D/g, '');
  
    // Trunca os números para caber no formato 9999-999
    numbers = numbers.slice(0, 7);
  
    // Divide e adiciona o hífen após os primeiros quatro números
    if (numbers.length > 4) {
      return `${numbers.slice(0, 4)}-${numbers.slice(4)}`;
    }
  
    return numbers;
  };

  const handlePostalCodeChange = (event) => {
    const formattedPostalCode = formatPostalCode(event.target.value);
    setPostalCode(formattedPostalCode);
  };
  
  
  return (
    <CalculatorWrapper>
    <CalculatorContainer>
      <h3>Calcule o custo da sua consulta</h3>
      <div className="input-group">
      <input
          className="form-control"
          type="text"
          placeholder="Código Postal"
          value={postalCode}
          onChange={handlePostalCodeChange} // Atualizado para usar o novo manipulador
        />
        {/* <input
          className="form-control"
          type="text"
          placeholder="Seguro de Saúde"
        /> */}
        <Button preset={"primary"} onClick={handleCalculate}>Calcular</Button>
      </div>
    </CalculatorContainer>
  </CalculatorWrapper>
  );
};

export default Calculator;
