import { screens } from "_theme/screens";
import styled from "styled-components";

const HomeContainer = styled.div`
  /* margin-top: -4rem; */

  .container {
    width: 7.5rem;
  }

  /* ${screens.sm}{
    background-image: url("img/banners/banner.png");
    width: 100vw;
    height: 10rem;
  } */
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 67.5rem;
  margin: 1.5rem auto;
`;

const MapContainer = styled.div`
width: 100%;
`
export { HomeContainer, Container, MapContainer };
