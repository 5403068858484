import React, { useState } from "react";
import { ContactsWrapper } from "./contacts.style";
import contacts from "_assets/img/svg/contactos.svg";
import { Button, ContactListWrapper, SocialLinks } from "_components/atoms";
import axios from 'axios';

const Contacts = () => {
  const [nome, setNome] = useState("");
  const [telefone, setTelefone] = useState("");
  const [email, setEmail] = useState("");
  const [descricao, setDescricao] = useState("");
  const [attemptedSubmit, setAttemptedSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const isValidPhone = (phone) => {
    return /^\d{9,15}$/.test(phone); 
  };

  const isValidName = (name) => {
    return /^[a-zA-Z\s]+$/.test(name); 
  };

  const handleSendEmail = async () => {
    if (!nome || !telefone || !email || !descricao || !isValidEmail(email) || !isValidPhone(telefone) || !isValidName(nome)) {
      setAttemptedSubmit(true);
      return;
    }

    setLoading(true);
    setError(false);
    setSuccess(false);

    try {
      const response = await axios.post('https://sua-api-endpoint.com/contatos', {
        nome,
        telefone,
        email,
        descricao
      });

      if (response.status === 200) {
        setSuccess(true);
        setNome("");
        setTelefone("");
        setEmail("");
        setDescricao("");
      } else {
        setError(true);
      }
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ContactsWrapper>
      <div className="contacts-container">
        <div className="left-container">
          <img src={contacts} alt="contacts-banner" />
        </div>

        <form className="right-container" onSubmit={(e) => e.preventDefault()}>
          <h1>Contactos</h1>
          <div className="contacts-container">
            <ContactListWrapper />
          </div>
          <div className="social-container">
            <p>Pode seguir-nos através...</p>
            <SocialLinks />
          </div>
          <div className="email-container">
            <h2>Pedidos de informação </h2>
            <div className="input-container">
              <input
                type="text"
                placeholder="Nome"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
                className={(!nome || !isValidName(nome)) && attemptedSubmit ? 'error' : ''}
              />
              {(!nome || !isValidName(nome)) && attemptedSubmit && <p className="error-message">Nome inválido</p>}
              <input
                type="phone"
                placeholder="Telefone"
                value={telefone}
                onChange={(e) => setTelefone(e.target.value)}
                className={(!telefone || !isValidPhone(telefone)) && attemptedSubmit ? 'error' : ''}
              />
              {(!telefone || !isValidPhone(telefone)) && attemptedSubmit && <p className="error-message">Telefone inválido</p>}
            </div>
            <div className="input-container">
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={(!email || !isValidEmail(email)) && attemptedSubmit ? 'error' : ''}
              />
              {(!email || !isValidEmail(email)) && attemptedSubmit && <p className="error-message">Email inválido</p>}
            </div>
            <div className="textarea-container">
              <textarea
                id="textarea"
                cols="30"
                rows="7"
                placeholder="Descreva aqui as informações que pretende"
                value={descricao}
                onChange={(e) => setDescricao(e.target.value)}
                className={!descricao && attemptedSubmit ? 'error' : ''}
              />
            </div>
          </div>
          <div className="button-container">
            <Button onClick={handleSendEmail} disabled={loading}>
              {loading ? 'Enviando...' : 'Enviar'}
            </Button>
            {success && <p className="success-message">Mensagem enviada com sucesso!</p>}
            {error && <p className="error-message">Erro ao enviar mensagem. Tente novamente.</p>}
          </div>
        </form>
      </div>
    </ContactsWrapper>
  );
};

export default Contacts;