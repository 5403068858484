import React from 'react';
import { LogoContainer } from './logo.style';

const Logo = () => {
  return (
    <LogoContainer href='/'>
        <img className="brand" src={require('_assets/img/logowb.png')} alt="Médicos na Manga Logo"></img>
    </LogoContainer>
        

  )
}

export default Logo