import React, { useEffect, useState } from "react";
import { CarouselWrapper } from "./carousel.style";
import { Button } from "_components/atoms";
import { Link } from "react-router-dom";

import bannerImage1 from "_assets/img/carousel/desktop/1.jpg";
import bannerImage2 from "_assets/img/carousel/desktop/2.jpg";
import bannerImage3 from "_assets/img/carousel/desktop/3.jpg";
import bannerImage4 from "_assets/img/carousel/desktop/4.jpg";

const phrases = [
  "Cuidamos de si, onde quer que esteja.",
  "A saúde começa em casa.",
  "Cuide de si, sem sair de casa.",
  "Médicos ao domicílio com experiência.",
  "Cuidado médico à medida das suas necessidades.",
  "Médicos online, saúde sem fronteiras.",
  "A sua saúde, a nossa missão.",
  "Médicos à distância de um clique.",
];

const Carousel = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentPhrase, setCurrentPhrase] = useState(0);

  const slides = [
    { id: 0, img: bannerImage1 },
    { id: 1, img: bannerImage2 },
    { id: 2, img: bannerImage3 },
    { id: 3, img: bannerImage4 },
  ];

  function showSlide(slideIndex) {
    if (slideIndex >= slides.length) {
      setCurrentSlide(0);
      setCurrentPhrase(0);
    } else if (slideIndex < 0) {
      setCurrentSlide(slides.length - 1);
      setCurrentPhrase(0);
    } else {
      setCurrentSlide(slideIndex);
      setCurrentPhrase(0);
    }
  }

  function nextSlide() {
    showSlide(currentSlide + 1);
  }

  function previousSlide() {
    showSlide(currentSlide - 1);
  }

  useEffect(() => {
    const phraseInterval = setInterval(() => {
      setCurrentPhrase((current) => (current + 1) % phrases.length);
    }, 4000); 
    return () => clearInterval(phraseInterval);
  }, []);

  useEffect(() => {
    const slideInterval = setInterval(nextSlide, 12000); 
    return () => clearInterval(slideInterval);
  }, [currentSlide]);

  return (
    <CarouselWrapper>
      <div className="carouselContainer">
        {slides.map((slide, index) => (
          <div
            loading="lazy"
            key={slide.id}
            className={`carouselImgs slide${slide.id} ${
              currentSlide === slide.id ? "active" : "hidden"
            }`}
            style={{ backgroundImage: `url(${slide.img})` }}
          >
            <div className="slide-info">
              <div className="slide-text">
                <h1 key={currentPhrase}>{phrases[currentPhrase]}</h1>
              </div>
             
              <a href="https://app.medicosnamanga.pt/servicos/pedidoconsulta" target="_blank" rel="noopener noreferrer">
      <Button className="button">CHAME UM MÉDICO AGORA!</Button>
    </a>
            </div>
          </div>
        ))}

        <div className="linkContainer">
          <div className="shortcut">
            <Link to="https://app.medicosnamanga.pt/servicos/pedidoconsulta" target="_blank" rel="noopener noreferrer">
              <p>Consultas ao domicílio</p>
            </Link>

            <span>|</span>
            <Link to="https://app.medicosnamanga.pt/servicos/acompanhamento" target="_blank" rel="noopener noreferrer">
              <p>Atendimento online personalizado</p>
            </Link>

            <span>|</span>
            <Link to="https://app.medicosnamanga.pt/servicos/medico24h" target="_blank" rel="noopener noreferrer">
              <p>Médicos 24h</p>
            </Link>

            <span>|</span>
            <Link to="https://app.medicosnamanga.pt/servicos/socios" target="_blank" rel="noopener noreferrer">
              <p>Acompanhamento personalizado para sócios</p>
            </Link>
          </div>
          <div className="dotContainer">
            <div className="arrow prev" onClick={previousSlide}></div>
            {slides.map((slide) => (
              <span
                key={slide.id}
                className={`dot ${currentSlide === slide.id ? "active" : ""}`}
                onClick={() => showSlide(slide.id)}
              ></span>
            ))}
            <div className="arrow next" onClick={nextSlide}></div>
          </div>
        </div>
      </div>
    </CarouselWrapper>
  );
};

export default Carousel;