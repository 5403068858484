import React from "react";
import { useTranslation } from "react-i18next";
import {
  AboutWrapper,
  Container1,
  Container2,
  Container3,
} from "./about.style";
import question from "_assets/img/graphics/stetos-question-mark.svg";
// import whyUs from "_assets/img/graphics/stetos-jacket.svg";
import whyUs from "_assets/img/graphics/Frame.svg";
import star from "_assets/img/graphics/stetos-star.svg";
import { ReactComponent as Bullet } from "_assets/img/graphics/Frame.svg";

import { useEffect } from "react";
// importing aos
import AOS from "aos";
import "aos/dist/aos.css";

import logo from "_assets/img/logo medicos na manga.svg";

const About = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  const { t } = useTranslation();
  return (
    <AboutWrapper>
      <div className="about-banner">
        <h1>Sobre Nós</h1>
      </div>
      <div className="top-container">
        <div className="container-left" data-aos="fade-up-right">
          <img src={logo} alt="Médicos na Manga" />
        </div>
        <div className="container-right" data-aos="fade-up-left">
          {/* <img src={question} alt="We are" /> */}
          <h1>{t("About.about_us")}</h1>
          <p>
            <strong>{t("About.about_us_text_strong")}</strong>
            {t("About.about_us_text")}
          </p>
          <p>
            <strong>{t("About.about_us_text_strong_2")}</strong>
          </p>
          <p>
            <strong>{t("About.about_us_text_strong_3")}</strong>
            {t("About.about_us_text_2")}
            <strong>{t("About.about_us_text_strong_4")}</strong>
          </p>
        </div>
      </div>
      <div className="middle-container" /*data-aos="fade-in"*/>
        <div className="middle-content">
          <div className="text-container">
            <p data-aos="slide-up">{t("About.about_us_text_middle")}</p>
          </div>
          {/* <h1 data-aos="slide-up">{t("About.why_us")}</h1> */}
        </div>
        <div className="img">
          <img src={whyUs} alt="Why us?" data-aos="slide-down" />
        </div>
      </div>
      <div className="bottom-container">
        <div className="bottom-content">
          {/* <img src={star} alt="Why us?" data-aos="slide-down" /> */}
          <h1 data-aos="slide-up">{t("About.differ_us")}</h1>
        </div>
        <div className="text-container">
          <div className="text-content" data-aos="fade-up" data-aos-delay="200">
            <h2 data-aos="fade-up">Adaptação e Personalização</h2>
            <p data-aos="fade-up">
              Seja uma visita domiciliária ou uma teleconsulta, estamos
              equipados para atender às suas necessidades específicas com a
              máxima conveniência e eficácia.
            </p>
          </div>
          <div className="text-content" data-aos="fade-up" data-aos-delay="400">
            <h2 data-aos="fade-up">Flexibilidade e Prontidão</h2>
            <p data-aos="slide-up">
              No conforto e intimidade do atendimento no seu lar, colocam-nos à
              frente num mercado cada vez mais distante e impessoal.
            </p>
          </div>
          <div className="text-content" data-aos="fade-up" data-aos-delay="600">
            <h2 data-aos="fade-up">Cuidado e Atenção</h2>
            <p data-aos="fade-up">
              Em cada interação, comprometemo-nos a oferecer uma experiência de
              cuidado autêntica, atenciosa e acima de tudo, humana.
            </p>
          </div>
        </div>
      </div>
      <Container1>
        <div className="text-container1">
          <div className="text-max1">
            <h1 data-aos="fade-right" data-aos-delay="200">
              Compromisso com a Excelência
            </h1>
            <p data-aos="fade-left" data-aos-delay="200">
              <strong>
                {" "}
                A excelência dos Médicos na Manga, transcende o conceito de
                serviço de saúde; é a concretização de uma parceria duradoura e
                abrangente no caminho para o bem-estar. A nossa abordagem não se
                limita ao tratamento de sintomas; é holística e preventiva,
                englobando todos os aspetos da saúde e adaptando-se de forma
                única às necessidades de cada paciente.
              </strong>
            </p>
          </div>
        </div>
        <div className="text-container2">
          <div className="text-max2">
            <h1 data-aos="fade-right" data-aos-delay="200">
              Os nossos Serviços
            </h1>
            <p data-aos="fade-left" data-aos-delay="200">
              <strong>
                {" "}
                Com uma gama completa de serviços, desde cuidados médicos a
                aconselhamento psicológico e nutricional, disponibilizamos um
                espectro de cuidados de saúde sem precedentes diretamente no
                conforto do seu lar, ou onde quer que esteja, presencialmente ou
                online.
              </strong>
            </p>

            <p data-aos="fade-left" data-aos-delay="200">
              Colaboramos com organizações locais, participamos em eventos de
              sensibilização e trabalhamos para melhorar a saúde e o bem-estar
              da comunidade que servimos.
            </p>
          </div>
        </div>
      </Container1>
      <Container2>
        <div className="content-container img"></div>
        <div className="content-container">
          <div className="text-max2">
            <h1>Os nossos objetivos</h1>
            <ul>
              <li data-aos="fade-left" data-aos-delay="150">
                <div className="icon">
                  <Bullet className="icon" />
                </div>

                <p>Melhorar o atendimento médico ao domicílio</p>
              </li>
              <li data-aos="fade-left" data-aos-delay="300">
                <div className="icon">
                  <Bullet className="icon" />
                </div>
                <p>Reduzir tempos de espera</p>
              </li>
              <li data-aos="fade-left" data-aos-delay="450">
                <div className="icon">
                  <Bullet className="icon" />
                </div>
                <p>
                  Eliminar processos burocráticos e criar um histórico digital
                  dos utentes
                </p>
              </li>
              <li data-aos="fade-left" data-aos-delay="600">
                <div className="icon">
                  <Bullet className="icon" />
                </div>
                <p>Combater a desvalorização dos profissionais de saúde</p>
              </li>
            </ul>
          </div>
        </div>
      </Container2>
      <Container3>
        <div className="text-container">
          <p data-aos="fade-up" data-aos-delay="150">
            Escolher os <strong>Médicos na Manga</strong>, não é apenas uma
            decisão, <strong>é um investimento na sua Saúde e Bem-estar</strong>{" "}
            com a garantia de <strong>tranquilidade e segurança</strong>
          </p>
        </div>
      </Container3>
    </AboutWrapper>
  );
};

export default About;
