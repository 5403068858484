
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { CalculadoraWrapper } from "./calculadora.style";
import { Button } from "_components/atoms";
import map from "_assets/img/map/mapa.jpg";

const precosPorLocalidade = {
  "Alijó": 150,
  "Boticas": 180,
  "Chaves": 110,
  "Mesão Frio": 150,
  "Mondim de Basto": 150,
  "Montalegre": 180,
  "Murça": 150,
  "Peso da Régua": 130,
  "Ribeira de Pena": 180,
  "Sabrosa": 130,
  "Santa Marta de Penaguião": 130,
  "Valpaços": 180,
  "Vila Pouca de Aguiar": 150,
  "Vila Real": 110,
  "Arcos de Valdevez": 130,
  "Caminha": 130,
  "Melgaço": 150,
  "Monção": 150,
  "Paredes de Coura": 130,
  "Ponte da Barca": 130,
  "Ponte de Lima": 130,
  "Valença": 150,
  "Viana do Castelo": 90,
  "Vila Nova de Cerveira": 130,
};

const Calculadora = () => {
  const location = useLocation();
  const initialState = location.state?.postalCode || "";
  const [currentPostalCode, setCurrentPostalCode] = useState(initialState);
  const [locationDetails, setLocationDetails] = useState(null);
  const [addressOptions, setAddressOptions] = useState([]);
  const [calculatedPrice, setCalculatedPrice] = useState(null);

  const handleButtonClick = () => {
    window.open('https://app.medicosnamanga.pt/servicos/pedidoconsulta', '_blank', 'noopener,noreferrer');
  };

  const formatPostalCode = (value) => {
    let numbers = value.replace(/\D/g, "");
    numbers = numbers.slice(0, 7);
    if (numbers.length > 4) {
      return `${numbers.slice(0, 4)}-${numbers.slice(4)}`;
    }
    return numbers;
  };

  const handlePostalCodeChange = (event) => {
    const formattedPostalCode = formatPostalCode(event.target.value);
    setCurrentPostalCode(formattedPostalCode);
  };

  const fetchPostalCodeData = async (postalCode) => {
    const [cp4, cp3] = postalCode.split("-");
    if (!cp4 || !cp3) {
      console.error("Invalid postal code:", postalCode);
      return [];
    }

    try {
      const url = `https://www.cttcodigopostal.pt/api/v1/402e32d068c94205b549414d9e523741/${cp4}-${cp3}`;
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Data from API:", data);
      const uniqueAddresses = Array.from(new Set(data.map((item) => item.morada)))
        .map((morada) => data.find((item) => item.morada === morada));
      return uniqueAddresses;
    } catch (error) {
      console.error("Error fetching postal code data:", error);
      return [];
    }
  };

  const handleAddressSelection = (event) => {
    const selectedIndex = event.target.value;
    const selectedAddress = addressOptions[selectedIndex];
    setLocationDetails(selectedAddress);

    const price = precosPorLocalidade[selectedAddress.localidade];
    setCalculatedPrice(price || 'Preço não disponível');
  };

  useEffect(() => {
    const loadData = async () => {
      if (!currentPostalCode) return;

      const addresses = await fetchPostalCodeData(currentPostalCode);
      setAddressOptions(addresses);
      if (addresses.length > 0) {
        setLocationDetails(addresses[0]);
        const price = precosPorLocalidade[addresses[0].localidade];
        setCalculatedPrice(price || 'Preço não disponível');
      } else {
        setLocationDetails(null);
        setCalculatedPrice(null);
      }
    };

    loadData();
  }, [currentPostalCode]);

  return (
    <CalculadoraWrapper>
      <div className="content-container">
        <img className="mapa" src={map} alt="Mapa de Portugal" />
      </div>
      <div className="content-container data">
        <div className="data">
          <h2>Calcule o custo da sua consulta</h2>
          <div className="post-code">
            <input
              type="text"
              className="form-control"
              value={currentPostalCode}
              onChange={handlePostalCodeChange}
              placeholder="Código Postal"
            />
            <p>
              Rua:
              <select
                onChange={handleAddressSelection}
                className="form-control"
              >
                {addressOptions.map((address, index) => (
                  <option key={index} value={index}>
                    {address.morada}
                  </option>
                ))}
              </select>
            </p>

            {locationDetails && (
              <div className="address-info">
                <p>Distrito: <span>{locationDetails.distrito}</span></p>
                <p>Concelho: <span>{locationDetails.concelho}</span></p>
                <p>Freguesia: <span>{locationDetails.freguesia}</span></p>
                <p>Localidade: <span>{locationDetails.localidade}</span></p>
                <p>Código Postal: <span>{currentPostalCode}</span></p>
              </div>
            )}
          </div>
          <div className="results">
            <Button className="schedule-button" onClick={handleButtonClick}>
              AGENDAR CONSULTA TELECONSULTA 25 €
            </Button><br />
            <div><br />
              <Button className="schedule-button" onClick={handleButtonClick}>
                AGENDAR CONSULTA PRESENCIAL <span className="price-red">{calculatedPrice ? `${calculatedPrice} €` : 'Preço não disponível'}</span>
              </Button><br />
            </div>
          </div>
        </div>
      </div>
    </CalculadoraWrapper>
  );
};

export default Calculadora;