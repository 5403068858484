import React from "react";

import { Container, HomeContainer, MapContainer } from "./home.style";
import Carousel from "_components/molecules/carousel/carousel";
import About from "_pages/user/about/about";
import { Footer } from "_components/organisms";
import { Calculator, DicasMenu, Mapa, Mappic } from "_components/molecules";
import HomeMenu from "_components/molecules/home-menu/home-menu";
import map from "_assets/img/map/mapa.jpg";

const Welcome = () => {
  return (
    <HomeContainer>
      <Carousel />
      <Calculator />
      <Container>
        <HomeMenu />
        <DicasMenu />
       
      </Container>
      <MapContainer>
        <Mapa/>
        
      </MapContainer>
     
      
    </HomeContainer>
  );
};

export default Welcome;
