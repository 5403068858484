// src/GoogleAnalytics.js
import React, { useEffect } from 'react';

const GoogleAnalytics = () => {
  useEffect(() => {
    // Adicionar o script do Google Analytics
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-GHC3XQF03N`;
    document.head.appendChild(script);

    // Adicionar a configuração do Google Analytics
    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'G-GHC3XQF03N');
    };
  }, []);

  return null;
};

export default GoogleAnalytics;
