import { Outlet } from "react-router-dom";

import Header from "../../header/header";
import { UserLayoutWrapper } from "./user-layout.style";
import Footer from "_components/organisms/footer/footer";


const UserLayout = () => {
  return (
    <UserLayoutWrapper>
      <Header />
      

        <Outlet />

      <Footer />
    </UserLayoutWrapper>
  );
};

export default UserLayout;
