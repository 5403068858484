import React from "react";
import { MapsWrapper } from "./mapa.style";
import map from "_assets/img/map/mapa.jpg";

const Mapa = () => {


  return (
    <MapsWrapper>
         <img src={map} alt="imagem portugal" />
    </MapsWrapper>
  );
};

export default Mapa;
