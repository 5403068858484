import React, { useState } from "react";
import { ContactsWrapper } from "./make-appointment.style";
import contacts from "_assets/img/svg/contactos.svg";
import { Button, ContactListWrapper, SocialLinks } from "_components/atoms";

const MakeAppointment = () => {
  const [nome, setNome] = useState("");
  const [telefone, setTelefone] = useState("");
  const [email, setEmail] = useState("");
  const [descricao, setDescricao] = useState("");


 

  return (
    <ContactsWrapper>
      <div className="contacts-container">
        <div className="left-container">
          <img src={contacts} alt="contacts-banner" />
        </div>

        <form className="right-container" onSubmit={(e) => e.preventDefault()}>
          <h1>Marcar Consulta</h1>
         
       
          <div className="email-container">
          
            <div className="input-container">
              <input
                type="text"
                placeholder="Nome"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
               
              />
              <input
                type="phone"
                placeholder="Telefone"
                value={telefone}
                onChange={(e) => setTelefone(e.target.value)}
              
              />
          
            </div>
            <div className="input-container">
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              
              />
                   <input
                type="number"
                placeholder="Numero Utente"
                value={telefone}
                onChange={(e) => setTelefone(e.target.value)}
               
              />
            </div>
            <div className="textarea-container">
              <textarea
                id="textarea"
                cols="30"
                rows="7"
                placeholder="Descreva aqui o tipo de consulta pretendido"
                value={descricao}
                onChange={(e) => setDescricao(e.target.value)}
                
              />
            </div>
          </div>
          <div className="button-container">
            <Button >Enviar</Button>
          </div>
        </form>
      </div>
    </ContactsWrapper>
  );
};

export default MakeAppointment;
