import styled from "styled-components";
import { colors } from "_theme/colors";
import { screens } from "_theme/screens";

const CarouselWrapper = styled.div`
  height: 65vh;

  ${screens.lg} {
          height: auto;
        }

  .carouselContainer {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
  }

  .slide-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    margin: 5rem;
height: 15rem;

    ${screens.lg} {
      margin: 1rem;
      height: 10rem;
    }

    .slide-text {
      text-align: left;
      padding: 0.5rem 1rem;
      margin-bottom: 1rem;

      ${screens.lg} {
      margin: 0;

    }

      h1,
      p {
        color: ${colors.text};
      }
      h1 {
        max-width: 700px;
        font-size: 48px;
        animation: fadeText 0.5s ease-in-out;

        /* text-transform: uppercase; */

        ${screens.lg} {
          font-size: 20px;
        }
      }
      @keyframes fadeText {
  from { opacity: 0; }
  to { opacity: 1; }
}
    }
    button {
      margin: 1rem;
      background-color: ${colors.primary};
      color: ${colors.white};

      &:hover {
        background-color: ${colors.primary_2};
      }
    }
  }

  .carouselImgs {
    width: 100%;
    height: 100%;
    animation: fade 1.5s ease-in-out;
    display: none;
    text-align: center;
    align-items: center;
    justify-items: center;
    justify-content: center;
    background-size: cover;

    ${screens.lg} {
          object-fit: contain;
          background-size: cover;
          background-position: left;
        }

    &.active {
      display: flex;
      justify-content: left;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;

  
    }
  }

  .prev,
  .next {
    font-size: xx-large;
    /* position: absolute; */
    /* width: 4rem; */
    /* height: 4rem; */
    /* padding: 0.7rem; */
    /* text-align: center; */
    /* top: 46%; */
    cursor: pointer;
    transition: transform 0.1s;

    &:hover {
      /* color: ${colors.primary};   */
      transform: scale(1.22);
    }
  }

  /* .prev {
      left: 10px;
    }

    .next {
      right: 10px;
    } */

  @keyframes fade {
    from {
      opacity: 0.4;
    }
    to {
      opacity: 1;
    }
  }
  /* Add this CSS to your stylesheet */

  .linkContainer {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
    z-index: 10;
    border-top: 1px solid ${colors.text};
    /* border-top: 1px solid white; */
    border-right: 0;
    border-bottom: 0;
    width: 100%;
    /* height: auto; */

    ${screens.lg} {
      border: none;
      justify-content: center;
    }
  }

  .shortcut {
    display: flex;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 20px;

    ${screens.lg} {
      display: none;
    }
    p {
      /* width: 100%; */
      /* text-transform: uppercase; */
      color: ${colors.text};
      /* color: ${colors.white}; */
      font-weight: 800;
      text-shadow: 0px 0px 2px white;
    }
    span {
      color: ${colors.text};
      /* color: ${colors.white}; */
    }

    a {
      margin: 0 auto;
    }
  }

  .dotContainer {
    /* position: absolute; */
    bottom: 0;
    right: 0;
    padding: 1.25rem;
    display: flex;
    justify-content: end;
    align-items: center;
    z-index: 10;
    border-left: 1px solid ${colors.text};
    /* height: 100%; */

    ${screens.lg} {
      border: none;
      padding: .5rem;
    }
  }

  .arrow {
    cursor: pointer;
    position: relative; /* Para posicionar os pseudoelementos corretamente */
    display: flex;
  }

  .arrow.prev::before,
  .arrow.next::before {
    content: "";
    border: solid white; /* Substitua 'black' pela sua cor, por exemplo, use variáveis ou valores diretos */
    /* border: solid white; Substitua 'black' pela sua cor, por exemplo, use variáveis ou valores diretos */
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 5px;
    transition: transform 0.3s ease;
  }

  .arrow.prev::before {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    /* margin-right: 5px; */
  }

  .arrow.next::before {
    transform: rotate(-45deg); /* Ajuste para a seta apontar para a direita */
    -webkit-transform: rotate(-45deg);
    /* margin-left: 5px;  */
  }

  .dot {
    height: 10px;
    width: 10px;
    background: ${colors.dots}; /* Default color for inactive dots */
    border-radius: 50%;
    border: 1px solid ${colors.white};
    display: inline-block;
    margin: 0 5px; /* Adjust the spacing between dots */
    cursor: pointer;
    transition: transform 0.1s;

    &:hover {
      /* color: ${colors.primary};   */
      transform: scale(1.22);
    }
  }

  .dot.active {
    /* background: ${colors.primary_2};
      border: ${colors.primary_2}; */
    background: ${colors.white};
    transform: scale(1.42);
  }

  .Button{}
`;

export { CarouselWrapper };
